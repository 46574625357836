import React, { useState } from "react";
import { graphql } from "gatsby"
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import Img from "gatsby-image"
import { OutboundLink } from 'gatsby-plugin-google-analytics';


//import css
import "./../../styles/industry.css"
//import components
import GetStartedBanner from "./../../components/get-started-footer"

const AgenciesIndustryPage = ({ data }) => (
  <Layout>
    <SEO
      title="Discover Odown: Essential Tool for Software Agencies"
      description="Ensure your clients' entire application stack is working flawlessly with Odown's continuous website performance monitoring. Optimize your software development agency's services with real-time insights."
      pathname={`/industry/agencies/`}
      image={{
        src: "/general-image-og--odown_for_agencies.jpg",
        width: 1600,
        height: 800,
      }}
    />
    <HeadingSection />
    <Description />
    <ReceiveAlertsSection imageData={data.FeatureBreakDown1.childImageSharp.fluid} />
    <TestAPIsSection imageData={data.FeatureBreakDown2.childImageSharp.fluid}/>
    <KnowFirstSection imageData={data.FeatureBreakDown.childImageSharp.fluid} />
    <GetStartedBanner />
  </Layout>
)

const HeadingSection = () => {
    const [getStartedButton, setGetStartedButton] = useState({
        name: "Get started for free",
        link: "https://app.odown.io/signup",
      });
    
      return (  
  <div className="u-features agencies-hero">
    <div className="container">
      <h1 className="u-features__heading-title">
        Your monitoring partner across all development projects
      </h1>
      <p className="hero_sub-title">
        As your clients expectations increase, you need a tool that continually checks proactively for performance issues. Odown has the advanced capabilities your clients expect.
      </p>
    </div>
    <div className="u-features__heading-btns center-button">
        <OutboundLink
          className="btn btn-primary"
          eventCategory="Launch the Odown App"
          eventAction="Click"
          eventLabel="Goto signup"
          href={getStartedButton.link}
          target="_blank"
        >
          {getStartedButton.name}
        </OutboundLink>
      </div>

  </div>
      );
};

const Description = () => (
    <section className="u-main-feature description-section">
    <div className="container">
          <p className="u-main-feature_tc-desc2">
          Nobody likes to wait for sluggish websites to load, especially your Customers' Customers customers. Continuous monitoring of your clients website’s performance is vital to ensure that their entire application stack is working properly and that you’re delivering world-class services..          </p>
        </div>
  </section>
)


const ReceiveAlertsSection = ({imageData}) => (
  <section className="u-main-feature receive-alert-section">
    <div className="container">
      <div className="u-main-feature__wrapper">
        <div className="u-main-feature__text-content">
          <h2 className="u-main-feature_tc-title">
            Receive alerts when issues arise
          </h2>
          <p className="u-main-feature_tc-desc">
            Before pushing a change into production, make sure it isn’t going to have a negative impact on the availability or functionality of your clients service. Web Application Monitoring provides the tools you need to identify and fix problems before your clients' users start experiencing them. Odown can notify you when issues arise on your customers' dev, staging or production environments.
          </p>
        </div>
        <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={imageData}
                alt="Agencies - Receive alert for agencies"
              />
            </div>
          </div></div>
    </div>
  </section>
)

const TestAPIsSection = ({ imageData }) => {
    return (
      <section className="u-main-feature left-text test-api-section">
        <div className="container">
          <div className="u-main-feature__wrapper">
            <div className="u-main-feature__media-content">
              <div className="illustration-wrapper">
                <Img
                  fadeIn={false}
                  loading="eager"
                  fluid={imageData}
                  alt="Agencies - Test API for agencies"
                />
              </div>
            </div>
            <div className="u-main-feature__text-content">
              <h2 className="u-main-feature_tc-title">
                Test if your APIs work correctly
              </h2>
              <div className="formatted-text">
                <p className="u-main-feature_tc-desc">
                  Do you trust that your API is performing as intended? Odown’s Monitoring brings issues directly to your team's attention.
                </p>
                <p className="u-main-feature_tc-desc indented">
                  &#10004; <strong>Test your APIs by setting up multiple HTTP requests.</strong> Retrieve data from each request and use it to perform tasks in other steps.
                </p>
                <p className="u-main-feature_tc-desc indented">
                  &#10004; <strong>Get alerted immediately - </strong> know the moment your API is in trouble with alerts and escalations from Odown.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  

  const KnowFirstSection = ({ imageData }) => (
    <section className="u-main-feature know-first-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <h2 className="u-main-feature_tc-title">
              Be the first to know when your client’s service is not working correctly
            </h2>
            <div className="formatted-text">
              <p className="u-main-feature_tc-desc">
                Knowing your online store and supporting API endpoints work as intended is vital.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Do your critical customer journeys work as intended?</strong> The service has optimal process flows to customer goals. Odown’s Web Monitoring can step through complex user actions and verify their function and speed.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Is your backend API responding as expected?</strong> If critical endpoints requests start failing, your team needs to know. Odown’s API Monitoring is the ultimate solution for monitoring your API responses.
              </p>
            </div>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={imageData}
                alt="Website monitoring - Everything you need, visualized"
              />
            </div>
          </div>
        </div>
        <div className="tableResponsive-industry">
          <div className="u-main-feature-table">
            <div className="u-main-feature-column">
              <h3>Uptime = happy clients</h3>
              <p className="u-main-feature_tc-desc1">
                Having high availability and a service faster is a great way to keep customers satisfied with your services. Odown can help you build out a baseline and make sure your product stays within those margins.
              </p>
            </div>
            <div className="u-main-feature-column">
              <h3>Not just a tool for production</h3>
              <p className="u-main-feature_tc-desc1">
                Use Odown monitoring in your dev environment as well as in your acceptance environments to test before it goes out into production.
              </p>
            </div>
            <div className="u-main-feature-column">
              <h3>Monitoring with global checkpoints</h3>
              <p className="u-main-feature_tc-desc">
                Just because your online store is operational in one country doesn’t guarantee it’s working everywhere. Monitor your service from 17 global checkpoints. Test from multiple locations simultaneously.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  

export const query = graphql`
  query {
    FeatureBreakDown: file(
      relativePath: { eq: "homepage--visualise-responsetime.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
       FeatureBreakDown1: file(
      relativePath: { eq: "product__status-page__option__custom-domain.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
      FeatureBreakDown2: file(
      relativePath: { eq: "product__api-monitoring__option__custom-http-request.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

export default AgenciesIndustryPage